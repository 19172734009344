import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import { Link } from 'gatsby';
import { Seo, Page, Section } from '../components';
import { Hermetiker } from '../parts/Hermetiker';
import { Authors } from '../parts/Authors';
import { Contact } from '../parts/Contact';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = Page;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>
      <Seo title="Die Hermetiker | Roman" />
      <Hermetiker />

           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      