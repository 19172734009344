import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import {
  Section,
  RoofLine,
  Title,
  Lead,
  Button,
  Image as UnstyledImage,
} from '../components';
import {
  COL_DARK,
  COL_MAIN,
  COL_BRIGHT,
  FONT_HEADER,
  BREAKPOINT,
} from '../variables';

const AreaContent = styled.div`
  display: flex;
  justify-content: flex-end;

  ${BREAKPOINT} {
    flex-flow: column;
    align-items: center;
  }
`;

const AreaText = styled.div`
  width: 62%;
  flex: 0 0 auto;

  ${BREAKPOINT} {
    width: auto;
  }
`;

const AreaImage = styled.div`
  flex: 0 0 auto;
  width: 220px;
  max-width: 25%;
  margin: 0 2rem;

  ${BREAKPOINT} {
    max-width: 100%;
  }

  image {
    width: 100%;
    height: auto;
  }
`;

const Area = styled(Section)`
  padding-top: 100px;
  &:nth-of-type(2n) {
    text-align: right;

    ${AreaText} {
      order: 0;
    }
    ${AreaImage} {
      order: 1;
    }

    ${AreaContent} {
      justify-content: flex-start;
    }
  }
`;

const Image = styled(UnstyledImage)`
  height: 320px;
`;

const Data = styled.p`
  font-family: ${FONT_HEADER.join(', ')};
  color: ${COL_MAIN};
  font-size: 1.2rem;
`;

export const Hermetiker = () => (
  <Fragment>
    <Area color={COL_DARK}>
      <AreaContent>
        <AreaImage>
          <Image name="hermetiker" />
        </AreaImage>
        <AreaText>
          <RoofLine inverted />
          <RoofLine inverted>Erstlings-Roman von A.M. Reis</RoofLine>
          <Title inverted>Die Hermetiker</Title>
          <Lead inverted>
            Als Claude herausfindet, dass er von Geburt an Hermetiker ist,
            steckt er bereits mitten in dem Abenteuer, das fortan sein Leben
            bestimmen wird. Die Suche nach einigen wertvollen Artefakten lässt
            ihn zu einer langen, spannenden Reise aufbrechen. Gemeinsam mit
            einem Expertenteam erforscht er eine mächtige, Jahrtausende alte
            Wissenschaft.
          </Lead>
          <p>
            <Button
              inverted
              href="https://amzn.to/2IbmJIE"
              event="herm-tb"
              target="_blank">
              Taschenbuch (Amazon)
            </Button>{' '}
            <Button
              inverted
              href="https://amzn.to/2HsQh2B"
              event="herm-ki"
              target="_blank">
              E-Book (Amazon/Kindle)
            </Button>{' '}
            <Button
              inverted
              href="https://www.thalia.de/shop/home/artikeldetails/ID142186697.html"
              event="herm-to"
              target="_blank">
              E-Book (Thalia/Tolino)
            </Button>
            <br />
            <Button
              inverted
              href="/die-hermetiker-probekapitel.pdf"
              event="herm-pdf"
              target="_blank">
              Probekapitel
            </Button>
          </p>
          <Data>
            <strong>Umfang</strong>: Paperback, 336 Seiten
            <br />
            <strong>ISBN</strong>: 978-3748126522
          </Data>
        </AreaText>
      </AreaContent>
    </Area>
    <Area>
      <AreaContent>
        <AreaImage>
          <Image name="matze" />
        </AreaImage>
        <AreaText>
          <Title>A.M. Reis – das sind wir</Title>
          <Lead>
            Anne und Matthias Reis. Wir haben uns 2011 kennengelernt und zwei
            Jahre später geheiratet. Seit wir unsere Zeit zusammen verbringen,
            entwickeln wir immer wieder gemeinsame kreative Projekte. Software
            Architekt Matze denkt sich seit frühester Kindheit Geschichten aus,
            Anne schreibt, seit sie denken kann. Bisher hat sie das vor allem in
            ihrem Blog und bei ihrer Arbeit als Redakteurin zum Ausdruck
            gebracht.
          </Lead>
        </AreaText>
      </AreaContent>
    </Area>
    <Area>
      <AreaContent>
        <AreaImage>
          <Image name="anne" />
        </AreaImage>
        <AreaText>
          <Title>...und so ging es weiter</Title>
          <Lead>
            Uns war immer klar, dass wir irgendwann gemeinsam etwas
            veröffentlichen würden, das unsere Phantasie vereint. Die Idee für
            „Die Hermetiker“ stammt von Technik- und Wissenschaftsenthusiast
            Matthias. Er war außerdem für den roten Faden verantwortlich.
            Herzblut-Autorin Anne hat die Charaktere entwickelt und die Handlung
            ausgearbeitet. Neben unserem Roman-Erstling „Die Hermetiker“ haben
            wir auch schon die Kurzgeschichte „Die vierte Welle“ veröffentlicht.
            Im Moment arbeiten wir wieder an neuen Ideen.
          </Lead>
        </AreaText>
      </AreaContent>
    </Area>
  </Fragment>
);
